/* eslint-disable no-use-before-define */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage, useField } from 'formik';
import * as Yup from 'yup';
import { CardBody, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { useLoaderData, useSubmit } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { serialize } from 'object-to-formdata';

import Resizer from 'react-image-file-resizer';
import ComponentCard from '../../components/ComponentCard';
import Axios from '../../services/Axios';
import Endpoints from '../../services/endpints';
import ReactGoogleMap from '../../components/googlemap/reactGooglemap';

import convertUrlsToFiles from '../../utils/stringUrlToFile';
import { useSelector } from 'react-redux';
import MultiSelect from '../ui/form/multiSelect';
import ImageUploader from '../../components/imageUploader';
import getOptimizedImgs from '../../utils/imageOptimizer';
// import PageName from './pageName';

const getBase64 = (file) => {
  console.log(file);
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = '';
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      console.log('Called', reader);
      baseURL = reader.result;
      console.log(baseURL);
      resolve(baseURL);
    };
    console.log(fileInfo);
  });
};

const pages = [
  {
    value: 1,
    label: 'Home',
  },
  {
    value: 2,
    label: 'benefits',
  },
  // {
  //   value: 3,
  //   label: 'Categories',
  // },
  // {
  //   value: 4,
  //   label: 'Contact',
  // },
  {
    value: 6,
    label: 'clients',
  },
  {
    value: 5,
    label: 'About',
  },
];
const Landing = () => {
  const [formikLoad, setFormikLoad] = useState(true);
  const [data, setData] = useState(null);
  const [homeImage, setHomeImage] = useState([]);
  const [load, setLoad] = useState(false);
  const [categoryTypes, setCategoryTypes] = useState(pages);

  const uploadImage = async (image) => {
    console.log(image);
    const formData = new FormData();
    // formData.append('file', image, image.name);
    formData.append('file', image);
    return axios.post(Endpoints.imageUpload(), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  const handleSave = async (data) => {
    console.log(data, 'dddd');
    setLoad(true);

    try {
      const res = await axios.post(Endpoints.modifyLanding(), { ...data, color: 'test color' });
      toast.success('Done', {});
    } catch (err) {
      console.log(err);
      if (err.response && err.response.msg) {
        toast.error(err.response.msg, {});
      } else {
        toast.error('server error', {});
      }
    } finally {
      setLoad(false);
    }
  };
  const profile = useSelector((state) => state.profileReducer.profile);

  useEffect(() => {
    const landingProfileLoader = async () => {
      // setFormikLoad(true);
      console.log(profile, ' useruseruseruseruser');
      try {
        const res = await Axios.get(Endpoints.landing.get(profile?.user.id));
        setData(res.data.landing);
        console.log(res.data.landing, 'lanfingDataaa');

        // return res.data;
      } catch (err) {
        console.log(err);
        return { msg: 'error' };
      } finally {
        setFormikLoad(false);
      }
    };
    if (profile) {
      landingProfileLoader();
    }
  }, [profile]);

  return (
    <div>
      <ToastContainer />
      {/* <h4 className=" test">Landing page information</h4> */}
      <Col md="12">
        <ComponentCard title="Site Information">
          {!formikLoad ? (
            <Formik
              initialValues={
                data
                  ? { ...data, pages: data.pages ? data.pages : pages }
                  : {
                      name: '',
                      domain: '',
                      home: {
                        h1: '',
                        p: '',
                      },
                      pages: pages,
                    }
              }
              onSubmit={handleSave}
              // validationSchema={Yup.object({
              //   name: Yup.string().min(2, 'min 2').required('required'),
              //   pages: Yup.array().min(2, 'min 2'),
              // })}
              render={({ errors, touched, resetForm, chan, setFieldValue, values }) => (
                <Form>
                  <FormGroup>
                    <Label htmlFor="common.brandname">VPN brandname</Label>
                    <Field
                      className={`form-control ${
                        errors.brandname && touched.brandname ? ' is-invalid' : ''
                      }`}
                      id="common.brandname"
                      name="common.brandname"
                      type="text"
                    />
                    <ErrorMessage
                      name="common.brandname"
                      component="div"
                      className="invalid-feedback"
                    />
                  </FormGroup>

                  {/* <PageName /> */}

                  <FormGroup>
                    <Label htmlFor="name">Landing Title</Label>
                    <Field
                      className={`form-control ${errors.name && touched.name ? ' is-invalid' : ''}`}
                      id="name"
                      name="name"
                      type="text"
                    />
                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <Field
                    label="Site Logo"
                    name="common.logo"
                    maxImgs={1}
                    component={ImageUploader}
                  />

                  <Field
                    label="Site favicon "
                    name="common.tabIcon"
                    maxImgs={1}
                    component={ImageUploader}
                  />
                  {/* <Field
                    name="pages"
                    // label="რესტორნის ტიპი"
                    component={MultiSelect}
                    options={categoryTypes}
                    defaultValues={pages}
                    maxSelectable={3}
                    defaultValue={pages}
                  /> */}
                  {/* {values.pages && values.pages.find((el) => el.label === 'Home') ? ( */}
                  <>
                    <div className="border-top">
                      <h4 className="mt-3">Home page</h4>
                    </div>
                    {values ? console.log(values, 'vvvvvvvvvv') : null}
                    <FormGroup>
                      <Label htmlFor="home.h1">home-heading</Label>
                      <Field
                        className={`form-control ${
                          errors['home.h1'] && touched['home.h1'] ? ' is-invalid' : ''
                        }`}
                        id="home.h1"
                        defaultValue="Empower your online journey with our VPN services."
                        name="home.h1"
                        type="textarea"
                        as="textarea"
                      />
                      <ErrorMessage name="home.h1" component="div" className="invalid-feedback" />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="home.p">home-text</Label>
                      <Field
                        className={`form-control ${
                          errors['home.p'] && touched['home.p'] ? ' is-invalid' : ''
                        }`}
                        id="home.p"
                        name="home.p"
                        type="textarea"
                        as="textarea"
                        defaultValue="Explore the internet securely, protect your digital privacy, and break free from online restrictions. Experience the next level of online freedom – welcome to a safer, more connected world."
                      />
                      <ErrorMessage name="home.p" component="div" className="invalid-feedback" />
                    </FormGroup>
                    {/* <ImageUploader maxImgs={1} images={homeImage} setImages={setHomeImage} /> */}
                    <Field name="home.img" maxImgs={1} component={ImageUploader} />
                  </>
                  {/* ) : null} */}

                  {/* {values.pages && values.pages.find((el) => el.label === 'benefits') ? ( */}
                  <>
                    <div className="border-top">
                      <h4 className="mt-3">benefits page</h4>
                    </div>
                    <FormGroup>
                      <Label htmlFor="benefits.h2">benefits-heading</Label>
                      <Field
                        className={`form-control ${
                          errors['benefits.h2'] && touched['benefits.h2'] ? ' is-invalid' : ''
                        }`}
                        id="benefits.h2"
                        name="benefits.h2"
                        type="textarea"
                        as="textarea"
                        defaultValue="Benefits for using a VPN"
                      />
                      <ErrorMessage
                        name="benefits.h2"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="benefits.p">benefits-text</Label>
                      <Field
                        className={`form-control ${
                          errors['benefits.p'] && touched['benefits.p'] ? ' is-invalid' : ''
                        }`}
                        id="benefits.p"
                        name="benefits.p"
                        type="textarea"
                        as="textarea"
                        defaultValue="Enhance your online experience with a VPN - enjoy increased privacy, secure data transmission, and unrestricted access to a world of content. Safeguard your digital life, protect against cyber threats, and unlock the full potential of the internet. Seamless, secure, and stress-free browsing awaits!"
                      />
                      <ErrorMessage
                        name="benefits.p"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="benefits.name1">benefits item 1 title</Label>
                      <Field
                        className={`form-control ${
                          errors.name && touched.name ? ' is-invalid' : ''
                        }`}
                        id="benefits.name1"
                        name="benefits.name1"
                        type="text"
                        defaultValue="Privacy online"
                      />
                      <ErrorMessage
                        name="benefits.name1"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="benefits.p1">benefits item 1 txt</Label>
                      <Field
                        className={`form-control ${
                          errors['benefits.p1'] && touched['benefits.p1'] ? ' is-invalid' : ''
                        }`}
                        id="benefits.p1"
                        name="benefits.p1"
                        type="textarea"
                        as="textarea"
                        defaultValue="Altering your IP address, concealing your virtual location, and encrypting your connection provide a heightened level of online privacy."
                      />
                      <ErrorMessage
                        name="benefits.p1"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <Field
                      name="benefits.img1"
                      label={'benefits item 1 image'}
                      maxImgs={1}
                      component={ImageUploader}
                    />
                    <FormGroup>
                      <Label htmlFor="benefits.name2">benefits item 2 title</Label>
                      <Field
                        className={`form-control ${
                          errors.name && touched.name ? ' is-invalid' : ''
                        }`}
                        id="benefits.name2"
                        name="benefits.name2"
                        type="text"
                        defaultValue="Digital security"
                      />
                      <ErrorMessage
                        name="benefits.name2"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="benefits.p2">benefits item 2 text</Label>
                      <Field
                        className={`form-control ${
                          errors['benefits.p2'] && touched['benefits.p2'] ? ' is-invalid' : ''
                        }`}
                        id="benefits.p2"
                        name="benefits.p2"
                        type="textarea"
                        as="textarea"
                        defaultValue="A VPN safeguards you against online threats such as man-in-the-middle attacks, packet sniffing, and potentially harmful Wi-Fi networks."
                      />
                      <ErrorMessage
                        name="benefits.p2"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>

                    <Field
                      name="benefits.img2"
                      label={'benefits item 2 image'}
                      maxImgs={1}
                      component={ImageUploader}
                    />
                    <FormGroup>
                      <Label htmlFor="benefits.name3">benefits item 3 title</Label>
                      <Field
                        className={`form-control ${
                          errors.name && touched.name ? ' is-invalid' : ''
                        }`}
                        id="benefits.name3"
                        name="benefits.name3"
                        type="text"
                        defaultValue="Open internet"
                      />
                      <ErrorMessage
                        name="benefits.name3"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="benefits.p3">benefits item 3 text</Label>
                      <Field
                        className={`form-control ${
                          errors['benefits.p3'] && touched['benefits.p3'] ? ' is-invalid' : ''
                        }`}
                        id="benefits.p3"
                        name="benefits.p3"
                        type="textarea"
                        as="textarea"
                        defaultValue="Using a VPN enables you to bypass online censorship and restrictions, allowing you to experience the freedom of an open and unrestricted internet.
                        "
                      />
                      <ErrorMessage
                        name="benefits.p3"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <Field
                      name="benefits.img3"
                      label={'benefits item 3 image'}
                      maxImgs={1}
                      component={ImageUploader}
                    />
                  </>
                  {/* ) : null} */}

                  {/* {values.pages && values.pages.find((el) => el.label === 'Categories') ? ( */}
                  <>
                    <div className="border-top">
                      <h4 className="mt-3">Categories page</h4>
                    </div>
                  </>
                  {/* ) : null} */}

                  {/* {values.pages && values.pages.find((el) => el.label === 'About') ? ( */}
                  <>
                    <div className="border-top">
                      <h4 className="mt-3">about page</h4>
                    </div>
                    <FormGroup>
                      <Label htmlFor="about.h2">about-heading</Label>
                      <Field
                        className={`form-control ${
                          errors['about.h2'] && touched['about.h2'] ? ' is-invalid' : ''
                        }`}
                        id="about.h2"
                        name="about.h2"
                        type="textarea"
                        as="textarea"
                        defaultValue="Why do you need a VPN?
                        "
                      />
                      <ErrorMessage name="about.h2" component="div" className="invalid-feedback" />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="about.p">about-text</Label>
                      <Field
                        className={`form-control ${
                          errors['about.p'] && touched['about.p'] ? ' is-invalid' : ''
                        }`}
                        id="about.p"
                        name="about.p"
                        type="textarea"
                        as="textarea"
                        defaultValue="In a world where privacy matters more than ever, nobody appreciates being under constant observation or tracking, regardless of having nothing to hide. Elevate your privacy standards; enter the realm of VPNs. Here's when it becomes essential:"
                      />
                      <ErrorMessage name="about.p" component="div" className="invalid-feedback" />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="about.name1">About item 1 title</Label>
                      <Field
                        className={`form-control ${
                          errors.name && touched.name ? ' is-invalid' : ''
                        }`}
                        id="about.name1"
                        name="about.name1"
                        type="text"
                        defaultValue="While using public Wi-Fi"
                      />
                      <ErrorMessage
                        name="about.name1"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="about.p1">About item 1 text</Label>
                      <Field
                        className={`form-control ${
                          errors['about.p1'] && touched['about.p1'] ? ' is-invalid' : ''
                        }`}
                        id="about.p1"
                        name="about.p1"
                        type="textarea"
                        as="textarea"
                        defaultValue="A VPN ensures the security of your connection on public Wi-Fi, granting you complete privacy while browsing. Public hotspots are vulnerable to various hacking methods that can compromise your data, but with a VPN, your online traffic remains invisible to potential threats."
                      />
                      <ErrorMessage name="about.p1" component="div" className="invalid-feedback" />
                    </FormGroup>
                    <Field
                      name="about.img1"
                      label={'About item 1 image'}
                      maxImgs={1}
                      component={ImageUploader}
                    />
                    <FormGroup>
                      <Label htmlFor="about.name2">About item 2 title</Label>
                      <Field
                        className={`form-control ${
                          errors.name && touched.name ? ' is-invalid' : ''
                        }`}
                        id="about.name2"
                        name="about.name2"
                        type="text"
                        defaultValue="While browsing"
                      />
                      <ErrorMessage
                        name="about.name2"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="about.p2">About item 2 text</Label>
                      <Field
                        className={`form-control ${
                          errors['about.p2'] && touched['about.p2'] ? ' is-invalid' : ''
                        }`}
                        id="about.p2"
                        name="about.p2"
                        type="textarea"
                        as="textarea"
                        defaultValue="Government agencies, marketers, and internet service providers are keen on tracking and collecting your browsing history, messages, and other private data. The most effective solution to keep your information confidential? Employ a VPN and ensure your data stays private and secure."
                      />
                      <ErrorMessage name="about.p2" component="div" className="invalid-feedback" />
                    </FormGroup>
                    <Field
                      name="about.img2"
                      label={'About item 2 image'}
                      maxImgs={1}
                      component={ImageUploader}
                    />
                    <FormGroup>
                      <Label htmlFor="about.name3">About item 3 title</Label>
                      <Field
                        className={`form-control ${
                          errors.name && touched.name ? ' is-invalid' : ''
                        }`}
                        id="about.name3"
                        name="about.name3"
                        type="text"
                        defaultValue="While traveling"
                      />
                      <ErrorMessage
                        name="about.name3"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="about.p3">About item 3 text</Label>
                      <Field
                        className={`form-control ${
                          errors['about.p3'] && touched['about.p3'] ? ' is-invalid' : ''
                        }`}
                        id="about.p3"
                        name="about.p3"
                        type="textarea"
                        as="textarea"
                        defaultValue="Access your home content seamlessly while globetrotting, including platforms like Netflix, with the assistance of a VPN. Install our VPN on your device and never miss a friend's social media post or an episode of your favorite series, no matter where you are.
                        "
                      />
                      <ErrorMessage name="about.p3" component="div" className="invalid-feedback" />
                    </FormGroup>
                    <Field
                      name="about.img3"
                      label={'About item 3 image'}
                      maxImgs={1}
                      component={ImageUploader}
                    />
                  </>
                  {/* ) : null} */}

                  {/* {values.pages && values.pages.find((el) => el.label === 'clients') ? ( */}
                  <>
                    <div className="border-top">
                      <h4 className="mt-3">clients page</h4>
                    </div>
                    <FormGroup>
                      <Label htmlFor="clients.h2">clients-heading</Label>
                      <Field
                        className={`form-control ${
                          errors['clients.h2'] && touched['clients.h2'] ? ' is-invalid' : ''
                        }`}
                        id="clients.h2"
                        name="clients.h2"
                        type="textarea"
                        as="textarea"
                        defaultValue="Endorsed by technology professionals and valued by users"
                      />
                      <ErrorMessage
                        name="clients.h2"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="clients.name1">client name 1 </Label>

                      <Field
                        className={`form-control ${
                          errors.name && touched.name ? ' is-invalid' : ''
                        }`}
                        id="clients.name1"
                        name="clients.name1"
                        type="text"
                        defaultValue="Alex Thompson"
                      />
                      <ErrorMessage
                        name="clients.name1"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="clients.p1">client 1 text</Label>
                      <Field
                        className={`form-control ${
                          errors['clients.p1'] && touched['clients.p1'] ? ' is-invalid' : ''
                        }`}
                        id="clients.p1"
                        name="clients.p1"
                        type="textarea"
                        as="textarea"
                        defaultValue="Exceptional VPN! The connection speed is impressive, and the user interface is straightforward. It seamlessly protects my online activities, allowing me to browse securely and access geo-restricted content. Highly recommend!
                        "
                      />
                      <ErrorMessage
                        name="clients.p1"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <Field
                      name="clients.img1"
                      label={'client name 1 image'}
                      maxImgs={1}
                      component={ImageUploader}
                    />
                    <FormGroup>
                      <Label htmlFor="clients.name2">client name 2 </Label>
                      <Field
                        className={`form-control ${
                          errors.name && touched.name ? ' is-invalid' : ''
                        }`}
                        id="clients.name2"
                        name="clients.name2"
                        type="text"
                        defaultValue="Maya Rodriguez"
                      />
                      <ErrorMessage
                        name="clients.name2"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="clients.p2">client 2 text</Label>
                      <Field
                        className={`form-control ${
                          errors['clients.p2'] && touched['clients.p2'] ? ' is-invalid' : ''
                        }`}
                        id="clients.p2"
                        name="clients.p2"
                        type="textarea"
                        as="textarea"
                        defaultValue="Reliable and efficient VPN service. Easy setup and a variety of server locations to choose from. It's been my go-to for maintaining privacy and bypassing content restrictions. Thumbs up to the tech team for a job well done!"
                      />
                      <ErrorMessage
                        name="clients.p2"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <Field
                      name="clients.img2"
                      label={'client  2 image'}
                      maxImgs={1}
                      component={ImageUploader}
                    />
                    <FormGroup>
                      <Label htmlFor="clients.name3">client name 3 </Label>
                      <Field
                        className={`form-control ${
                          errors.name && touched.name ? ' is-invalid' : ''
                        }`}
                        id="clients.name3"
                        name="clients.name3"
                        type="text"
                        defaultValue="Daniel Chang"
                      />
                      <ErrorMessage
                        name="clients.name3"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="clients.p3">client 3 text</Label>
                      <Field
                        className={`form-control ${
                          errors['clients.p3'] && touched['clients.p3'] ? ' is-invalid' : ''
                        }`}
                        id="clients.p3"
                        name="clients.p3"
                        type="textarea"
                        as="textarea"
                        defaultValue="Top-notch VPN experience! The encryption is robust, ensuring my online presence is secure. I appreciate the quick response from customer support whenever I have a query. Definitely a must-have for anyone serious about online privacy."
                      />
                      <ErrorMessage
                        name="clients.p3"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <Field
                      name="clients.img3"
                      label={'client 3 image'}
                      maxImgs={1}
                      component={ImageUploader}
                    />
                  </>
                  {/* ) : null} */}
                  <CardBody className="border-top gap-2 d-flex align-items-center">
                    <Button disabled={load} type="submit" className="btn btn-success">
                      {load ? 'loading...' : 'save'}
                    </Button>
                    <Button
                      onClick={() => {
                        resetForm();
                      }}
                      type="button"
                      className="btn btn-dark ml-2"
                    >
                      Reset
                    </Button>
                  </CardBody>
                </Form>
              )}
            />
          ) : (
            'loading...'
          )}
        </ComponentCard>
      </Col>
    </div>
  );
};

export const landingProfileLoader = () => {
  return 'ok';
  // const profile = useSelector((state) => state.profileReducer.profile);

  // console.log(profile, ' useruseruseruseruser');
  // try {
  //   const res = await Axios.get(Endpoints.landing.get(1));
  //   return res.data;
  // } catch (err) {
  //   console.log(err);
  //   return { msg: 'error' };
  // }
};

export default Landing;
