import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import Axios, { baseUrl } from '../../services/Axios';
import Endpoints from '../../services/endpints';
import { getCurrentUser } from '../../store/profile/profileSlice';
import Loader from '../../layouts/loader/Loader';

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { loading, profile } = useSelector((state) => {
    return state.profileReducer;
  });
  useEffect(() => {
    dispatch(getCurrentUser());
  }, [pathname]);

  useEffect(() => {
    if (!loading && !profile) {
      navigate('/auth/loginformik');
    }
    if (profile?.user?.token) {
      console.log('Axios.defaults.headers', profile?.user?.token);
      // console.log(profile, 'profileprofileprofile');
      Axios.defaults.headers.common['Token'] = `${profile?.user?.token}`;
      Axios.defaults.headers.common['Content-Type'] = `"application/json`;
      Axios.defaults.baseURL = baseUrl;
    }
  }, [loading, profile, profile?.user?.token, pathname]);

  return children;
};

export default ProtectedRoute;
