const Endpoints = {
  auth: () => `/register`,
  signIn: () => '/login',
  resendEmailVerification: (id, code) => `/verify/email/${id}/${code}`,
  recoverPassword: () => `/restaurant/`,
  saveRestaurantInfo: (token) => `/getUser/${token}`,
  modifyLanding: () => `modify/landing`,

  getRestaurantInfo: () => `/restaurant/`,
  domain: {
    create: '/create/domain',
    get: '/get/domain',
  },
  profile: {
    get: (token) => `/getUser/${token}`,
    changePassword: '/profile/change/pass',
    updatePayoutInfo: '/payout/info',
    updateProfileInfo: '/profile/fullname/update',
  },
  landing: {
    get: (id) => `/landing/${id}`,
  },
  categories: {
    get: () => '/admin/categories',
  },
  imageUpload: () => '/image/uploader',
  specialOffer: {
    post: () => '/specialOffer',
    delete: () => '/specialOffer',
    getAll: () => '/specialOffer/getAll',
    changeStatus: () => '/specialOffer/changeStatus',
  },
  seller: {
    transactions: `/seller/transactions`,
    users: `/seller/users`,
    payout: `/seller/payout`,
    addPayout: `/add/payouts_method`,
    deletePayout: (id) => `/delete/payouts_method/${id}`,
    setDefaultPayoutMethod: `/make/payout/default`,
    payoutsList: `/payouts_methods`,
    referrals: '/referrals',
    recoverPassword: '/reset/password',
    resetPassword: '/recover/password',
  },
  chartData: '/seller/charts',
};

export default Endpoints;
