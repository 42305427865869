import axios from 'axios';

export const baseUrl = 'https://wlapi.vpnresellers.com/api';

const getAuthToken = () => {
  const authToken = localStorage.getItem('token');
  return authToken;
};

const token = getAuthToken();

const Axios = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    Token: token,
    // Add any other custom headers you need
  },
});

export default Axios;
